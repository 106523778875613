/* Common */
.icon-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  outline: inherit;
}

.icon-button:focus-visible {
  outline: 2px solid var(--pfz-color-secondary-800);
  outline-offset: 8px;
  border-radius: 4px;
}

.icon-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* VARIANT - ROUNDED-BUTTON */
.icon-button--rounded {
  border-radius: 100%;
  border: var(--border-radius-sm) solid var(--pfz-tag-line-color);
  background: var(--pfz-color-white);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
