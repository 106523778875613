.navbar {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  background-color: var(--pfz-color-ghost-white);
  border-right: 1px solid #d4d4d8;
  transition: width 0.5s ease;
}

.navbar.collapsed {
  width: 80px;
}

.brand-nav-section {
  padding: 16px;
}

.brand-nav-section .brand-logo {
  display: flex;
}

.brand-nav-section .create-new {
  margin-top: 40px;
  padding: 12px 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition:
    width 0.3s ease,
    padding 0.3s ease;
  white-space: nowrap;
  background-color: transparent;
  width: auto;
}

.brand-nav-section.collapsed {
  display: flex;
  flex-direction: column;
}

.brand-nav-section .create-new .text {
  display: inline-block;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Clip overflowing text */
  transition: opacity 0.3s ease;
}

.brand-nav-section .create-new.collapsed {
  padding: 12px;
  width: 48px;
  height: 48px;
}

.brand-nav-section.collapsed .create-new {
  width: auto;
}

.admin-menu,
.footer-menu {
  border-top: 1px solid #d4d4d8;
}

.footer-menu {
  height: 144px;
}

.other-menu {
  margin-top: auto;
}

.green-circle-icon {
  position: absolute;
  left: 26px;
  top: 8px;
  width: 14px;
  height: 14px;
}

.expanded-green-circle-icon {
  position: relative;
  left: -18px;
  top: -9px;
  width: 14px;
  height: 14px;
}