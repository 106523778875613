@import './styles/reset.css';
@import './styles/global.css';
@import './styles/tokens.css';
@import './styles/flexbox.css';
@import './styles/grid.css';
@import './styles/fonts.css';

html {
  font-size: var(--pfz-base-font-size);
}

body {
  margin: 0;
  font-family: var(--font-family-primary);
  font-size: var(--pfz-base-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-family-primary);
}

.single-select {
  cursor: pointer !important;
  /* background: var(--color-bg-light); */
  color: var(--font-family-primary);
  font-family: var(--font-family-primary);
  height: 3.625rem;
  padding: 0px 1rem !important;
  border-radius: 0.5rem !important;
  border: none !important;
  opacity: unset !important;
}

.single-select > .react-dropdown-select-content > span {
  position: absolute;
  color: var(--pfz-text-dark-blue);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: var(--pfz-line-height-20);
  bottom: 0.5rem;
  text-transform: capitalize;
}

.single-select.single-select__simple > .react-dropdown-select-content > span {
  position: unset;
  color: var(--pfz-color-black);
  font-size: var(--pfz-font-size-3);
  font-weight: var(--pfz-font-weight-400);
  line-height: 24px;
  text-transform: capitalize;
}

.single-select .react-dropdown-select-input {
  color: var(--pfz-text-dark-blue);
  font-family: var(--font-family-primary);
  font-weight: var(--pfz-font-weight-500);
  font-size: var(--pfz-font-size-2);
  line-height: 20px;
  margin: 0;
  position: absolute;
  caret-color: var(--pfz-text-search-input-placeholder);
}

.single-select .react-dropdown-select-input::placeholder {
  color: var(--font-family-primary) !important;
  font-family: var(--font-family-primary) !important;
  font-weight: var(--pfz-font-weight-400) !important;
  font-size: var(--pfz-font-size-2) !important;
}

.single-select .react-dropdown-select-input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--font-family-primary) !important;
  font-family: var(--font-family-primary) !important;
  font-weight: var(--pfz-font-weight-400) !important;
  font-size: var(--pfz-font-size-2) !important;
}

.single-select .react-dropdown-select-input::-moz-placeholder {
  color: var(--font-family-primary) !important;
  font-family: var(--font-family-primary) !important;
  font-weight: var(--pfz-font-weight-400) !important;
  font-size: var(--pfz-font-size-2) !important;
}
.single-select .react-dropdown-select-dropdown {
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  width: 400px;
  border: none;
}

.single-select .react-dropdown-select-dropdown-handle svg {
  width: 20px;
  height: 20px;
}

.select-dropdown__options_inner {
  z-index: 2000;
  max-width: 400px;
  position: fixed;
  overflow: auto;
  color: var(--font-family-primary);
  font-weight: var(--pfz-font-weight-400);
  border-radius: 0.40625rem !important;
  border: 1px solid #d0d5dd !important;
  background: var(--color-bg-light);
  box-shadow:
    0px -1px 5px 0px rgba(0, 0, 0, 0.05),
    0px 4px 7px 0px rgba(0, 0, 0, 0.1),
    0px 2px 5px 0px rgba(0, 0, 0, 0.15);
}

.single-select .react-dropdown-select-dropdown::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.single-select .react-dropdown-select-dropdown::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--pfz-background-primary);
  position: absolute;
}

/* Handle */
.single-select .react-dropdown-select-dropdown::-webkit-scrollbar-thumb {
  background: var(--pfz-interactive-scrollbar-default);
  border-radius: 10px;
  height: 8.125rem;
  width: 4px;
}
.single-select .react-dropdown-select-item-selected {
  background: var(--pfz-color-primary-100) !important;
}

.single-select .react-dropdown-select-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  padding: 1rem 1.5rem;
  align-items: center;
  border: none;
  color: var(--pfz-text-primary);
  font-size: var(--pfz-font-size-3);
  font-weight: var(--pfz-font-weight-400);
  color: var(--pfz-text-primary);
  line-height: 24px; /* 24px */
  text-transform: capitalize;
}

.single-select .react-dropdown-select-item:hover {
  background: var(--pfz-color-primary-100);
}
.single-select .react-dropdown-select-item.react-dropdown-select-item-selected,
.react-dropdown-select-item.react-dropdown-select-item-active {
  background: var(--pfz-color-primary-100);
  color: var(--pfz-text-primary);
}

.single-select:focus,
.single-select:focus-within {
  box-shadow: none !important;
}

.react-dropdown-select.single-select:focus-visible:not([disabled]) {
  outline: 1px solid var(--pfz-color-secondary-800);
  outline-offset: 8px;
}

.single-select .react-dropdown-select-item:focus-visible {
  background: var(--pfz-color-primary-100);
}

.tab-accessible:focus-visible {
  outline: 1px solid var(--pfz-color-secondary-800);
  outline-offset: 8px;
}

.full-height {
  height: 100%;
}

.preloader_wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
