.logo-wrapper {
  display: flex;
  flex-direction: column;
}

.logo {
  cursor: pointer;
}

.logo-wrapper__disclaimer {
  color: var(--pfz-text-primary);
  font-family: var(--font-family-secondary);
  font-size: 10px;
  font-style: italic;
  font-weight: var(--pfz-font-weight-700);
  letter-spacing: 0.192px;
}

.collapsed__logo {
  padding: 0 8px;
  min-height: 47px;
}
