.section-loading-indicator__icon {
  margin: 5px 0px;
}
.section-loading-indicator__text {
  color: var(--pfz-color-gray-400);
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: var(--pfz-font-weight-400);
  line-height: 150%;
  margin-left: 12px;
}
