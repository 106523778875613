.flex {
  display: flex;
}

.flex-vertical-center {
  align-items: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-justify-content-start {
  justify-content: flex-start;
}

.flex-justify-content-center {
  justify-content: center;
}

.flex-vertical-baseline {
  align-items: baseline;
}
