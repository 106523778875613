.page__loader-wrapper {
  display: grid;
  width: 100%;
  height: 100vh;
}
.page__loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
