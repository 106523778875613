.page-layout__wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
}

.page-layout__col-left {
  height: 100vh;
}

.page-layout__col-right {
  height: 100vh;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-main {
  flex: 1;
  height: 100%;
}
