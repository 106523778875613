.tooltip {
  display: inline-flex;
  position: relative;
  align-items: center;
}

.tooltip__text {
  visibility: visible;
  position: absolute;
  z-index: 1000;
  width: 299px;
  padding: 20px 12px;
  gap: 8px;
  border-radius: var(--border-radius-2xl);
  border: 1px solid var(--pfz-border-default-secondary);
  background: var(--pfz-background-primary);
  box-shadow: 0px 4px 24px 0px var(--pfz-tooltip-box-shadow-color);
  text-align: center;
  font-size: var(--pfz-font-size-2);
  font-weight: var(--pfz-font-weight-400);
  line-height: 20px;
  color: var(--pfz-text-default-color);
}

.tooltip__text--hidden {
  visibility: hidden;
  display: none;
}

/* TOOLTIP POSITION: TOP_CENTER */
.tooltip__text--top-center {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.tooltip__text--top-center:before {
  content: "";
  display: block;
  position: absolute;
  left: 140px;
  top: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: var(--pfz-border-default-secondary);
}

.tooltip__text--top-center:after {
  content: "";
  display: block;
  position: absolute;
  left: 141px;
  top: 100%;
  transform: translateY(-1px);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: white;
}

/* TOOLTIP POSITION: BOTTOM_CENTER */
.tooltip__text--bottom-center {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 10%);
}

.tooltip__text--bottom-center:before {
  content: "";
  display: block;
  position: absolute;
  left: 140px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--pfz-border-default-secondary);
}

.tooltip__text--bottom-center:after {
  content: "";
  display: block;
  position: absolute;
  left: 141px;
  bottom: 100%;
  transform: translateY(1px);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

/* TOOLTIP POSITION: BOTTOM_LEFT */
.tooltip__text--bottom-left {
  top: 100%;
  left: 50%;
  transform: translate(-10%, 10%);
}

.tooltip__text--bottom-left:before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--pfz-border-default-secondary);
}

.tooltip__text--bottom-left:after {
  content: "";
  display: block;
  position: absolute;
  left: 21px;
  bottom: 100%;
  transform: translateY(1px);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

/* TOOLTIP POSITION: BOTTOM_RIGHT */
.tooltip__text--bottom-right {
  top: 100%;
  left: 50%;
  transform: translate(-90%, 10%);
}

.tooltip__text--bottom-right:before {
  content: "";
  display: block;
  position: absolute;
  left: 260px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--pfz-border-default-secondary);
}

.tooltip__text--bottom-right:after {
  content: "";
  display: block;
  position: absolute;
  left: 261px;
  bottom: 100%;
  transform: translateY(1px);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

/* TOOLTIP POSITION: TOP_RIGHT */
.tooltip__text--top-right {
  bottom: 100%;
  left: 50%;
  transform: translate(-90%, -10%);
}

.tooltip__text--top-right:before {
  content: "";
  display: block;
  position: absolute;
  left: 260px;
  top: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: var(--pfz-border-default-secondary);
}

.tooltip__text--top-right:after {
  content: "";
  display: block;
  position: absolute;
  left: 261px;
  top: 100%;
  transform: translateY(-1px);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: white;
}

/* TOOLTIP POSITION: TOP_LEFT */
.tooltip__text--top-left {
  bottom: 100%;
  left: 50%;
  transform: translate(-10%, -10%);
}

.tooltip__text--top-left:before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  top: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: var(--pfz-border-default-secondary);
}

.tooltip__text--top-left:after {
  content: "";
  display: block;
  position: absolute;
  left: 21px;
  top: 100%;
  transform: translateY(-1px);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: white;
}

/* TOOLTIP POSITION: RIGHT */
.tooltip__text--right {
  transform: translate(10%, -10%);
}

.tooltip__text--right:before {
  content: "";
  display: block;
  position: absolute;
  left: -21px;
  top: 35%;
  transform: rotate(-90deg);
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--pfz-border-default-secondary);
}

.tooltip__text--right:after {
  content: "";
  display: block;
  position: absolute;
  left: -18px;
  top: 36%;
  transform: rotate(-90deg);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

/* TOOLTIP POSITION: LEFT */
.tooltip__text--left {
  transform: translate(-105%, -10%);
}

.tooltip__text--left:before {
  content: "";
  display: block;
  position: absolute;
  right: -21px;
  top: 35%;
  transform: rotate(-270deg);
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--pfz-border-default-secondary);
}

.tooltip__text--left:after {
  content: "";
  display: block;
  position: absolute;
  right: -18px;
  top: 36%;
  transform: rotate(-270deg);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}
