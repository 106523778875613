.nav-section {
  padding: 16px;
}

.menu-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
