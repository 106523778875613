.grid {
  display: grid;
  gap: var(--spacing-06);
}

.columns-2 {
  grid-template-columns: repeat(2, var(--pfz-grid-track-size-64));
}

.columns-4 {
  grid-template-columns: repeat(4, var(--pfz-grid-track-size-64));
}

.columns-6 {
  grid-template-columns: repeat(6, var(--pfz-grid-track-size-64));
}

.columns-8 {
  grid-template-columns: repeat(8, var(--pfz-grid-track-size-64));
}

.columns-10 {
  grid-template-columns: repeat(10, var(--pfz-grid-track-size-64));
}

.columns-12 {
  grid-template-columns: repeat(12, var(--pfz-grid-track-size-64));
}

.span-1 {
  grid-column-end: span 1;
}

.span-2 {
  grid-column-end: span 2;
}

.span-3 {
  grid-column-end: span 3;
}

.span-4 {
  grid-column-end: span 4;
}

.span-5 {
  grid-column-end: span 5;
}

.span-6 {
  grid-column-end: span 6;
}

.span-7 {
  grid-column-end: span 7;
}

.span-8 {
  grid-column-end: span 8;
}

.span-9 {
  grid-column-end: span 9;
}

.span-10 {
  grid-column-end: span 10;
}

.span-11 {
  grid-column-end: span 11;
}

.span-12 {
  grid-column-end: span 12;
}

/* temp styles */
.item {
  background-color: burlywood;
  height: 50px;
}
