:root {
  /* Palette Colors */
  --pfz-color-primary-100: #e6f4ff; /* icy blue */
  --pfz-color-primary-200: #cceaff; /* water blue */
  --pfz-color-primary-300: #c5e5ff; /* diamond blue */
  --pfz-color-primary-400: #66bfff; /* sky blue */
  --pfz-color-primary-500: #80d5ff; /* flight blue */
  --pfz-color-primary-600: #40b0ff; /* ADA light blue */
  --pfz-color-primary-700: #003e7e; /* blue */
  --pfz-color-primary-800: #0000c9; /* mid blue */
  --pfz-color-primary-900: #00448e; /* reflex blue */
  --pfz-color-primary-sky: #0f61f2;
  --pfz-color-secondary-700: #00007e; /* dark blue */
  --pfz-color-secondary-800: #02005e; /* navy blue */
  --pfz-color-secondary-900: #00004e; /* octacean blue */
  --pfz-color-white: #ffffff;
  --pfz-color-white-100: #fafafa;
  --pfz-color-ghost-white: #f8fcff;
  --pfz-color-gray-0: #f4f4f5;
  --pfz-color-gray-10: #e4e4e7;
  --pfz-color-gray-50: #fdfdfd;
  --pfz-color-gray-100: #f0f0f0;
  --pfz-color-gray-150: #bebebe;
  --pfz-color-gray-200: #e0dfdc;
  --pfz-color-gray-250: #cbd5e1;
  --pfz-color-gray-300: #898888;
  --pfz-color-subtle-gray: #71717a;
  --pfz-color-gray-350: #52525b;
  --pfz-color-gray-400: #4c4a4a;
  --pfz-color-gray-500: #232323;
  --pfz-color-gray-600: #4b4949;
  --pfz-color-green-100: #ebfef4;
  --pfz-color-green-200: #008253;
  --pfz-color-green-300: #2f7c0b;
  --pfz-color-green-400: #009b5f;
  --pfz-color-orange-100: #fffaf5;
  --pfz-color-orange-200: #fffaec;
  --pfz-color-orange-400: #ff7900;
  --pfz-color-orange-500: #cc5802;
  --pfz-color-red-100: #fff1f1;
  --pfz-color-red-300: #c60e2d;
  --pfz-color-red-400: #5b000f;
  --pfz-color-blue-100: #f6faff;
  --pfz-color-blue-200: #334155;
  --pfz-color-blue-500: #3a43ff;
  --pfz-color-black: #000000;
  --pfz-color-black-100: #111111;
  --pfz-color-neutral-dark: #4b4b4b;
  --pfz-color-base-dark-gray: #727071;
  --pfz-color-shadow-gray: #c0c0c0;

  /* Elemnent States */
  --pfz-color-disabled: #d4d4d8;

  /* Background Colors */
  --pfz-background-primary: var(--pfz-color-white);
  --pfz-background-secondary: #f7f7ff;
  --pfz-background-tertiary: #f5f5f9;
  --pfz-background-grid: #f2f7f8;
  --pfz-background-menu-selection: #eef2fc; /* new value added */
  --pfz-background-error: #fef8f9;
  --pfz-background-success: #eff5f3;
  --pfz-background-informational: var(--pfz-color-ghost-white);
  --pfz-background-BERT-excellent: #68e6b9;
  --pfz-background-BERT-good: #ffc066;
  --pfz-background-BERT-notgreat: #ef6774;
  --pfz-background-panel-scrollable: #f0f0fc;
  --pfz-background-overlay: #e6cffd;
  --pfz-background-interactive-disabled: #f9f9f9;
  --pfz-background-drag-over: #d1dfe9;
  --pfz-background-slider-thumb-dark-blue: #01004e;
  --pfz-background-surface-info-default: #f8fcfc;
  --pfz-background-light-blue: #e4e9ff;
  --pfz-background-search-input-hover: #fafafa;
  --pfz-background-surface-base-secondary: #f4f4f5;
  --pfz-background-surface-base-hover: #fafafa;
  --pfz-background-surface-disabled: #fafafa;

  /* Text Colors */
  --pfz-text-default-color: #09090b;
  --pfz-text-primary: var(--pfz-color-black);
  --pfz-text-secondary: #0000cc;
  --pfz-text-disabled: #d4d4d8;
  --pfz-text-tertiary: #4c5459;
  --pfz-text-header: #3b3b3e;
  --pfz-text-on-dark: var(--pfz-color-white);
  --pfz-text-input-placeholder: #c7c7cd;
  --pfz-text-utility-link: #0046c1;
  --pfz-text-utility-success: #05a357;
  --pfz-text-utility-error: #e11900;
  --pfz-tag-line-color: #0000c9;
  --pfz-text-instruction: #5e5e5e;
  --pfz-text-secondary-error: #c60e2d;
  --pfz-text-content-tagline: #acacac;
  --pfz-text-dark-blue: #020617;
  --pfz-text-black: #050505;
  --pfz-text-search-input-default: #09090b;
  --pfz-text-search-input-placeholder: #71717a;
  --pfz-text-warning-default: #461b04;
  --pfz-text-success-default: #012d20;
  --pfz-text-info-default: #122959;
  --pfz-text-error-default: #4e0312;
  --pfz-text-disabled-button: #f0f3ff;

  /* Icon Colors */
  --pfz-icons-primary: var(--pfz-color-primary-800);
  --pfz-icons-secondary: var(--pfz-color-black);
  --pfz-icons-disabled: var(--pfz-color-gray-200);
  --pfz-icons-primary-on-hover: #0000ab;
  --pfx-icons-dark-gray: #727071;
  --pfz-icon-default: #18181b;
  --pfz-icons-success: #045036;
  --pfz-icons-warning: #823a0c;
  --pfz-icons-info: #122959;
  --pfz-icons-error: #8b102d;

  /* Interactive Colors */
  /* new values as per screen desgin */
  --pfz-interactive-primary-on-light: #0701c9;
  --pfz-interactive-primary-active-on-light: #0c0c75;
  --pfz-interactive-secondary-on-light: var(--pfz-color-white);
  --pfz-interactive-tertiary-on-light: #f2f2f7;
  --pfz-interactive-hover-on-light: var(--pfz-interactive-primary-on-light);
  --pfz-interactive-disabled: #f0efee;
  --pfz-interactive-scrollbar-default: #e8e8e8;
  --pfz-interactive-scrollbar-current: #77797d;
  --pfz-interactive-button-inactive: #6e7781;
  --pfz-interactive-button-active: var(--pfz-color-black);
  --pfz-interactive-icon-disabled: #e0dfdc;

  /* Border Colors */
  --pfz-border-primary-dropshadow: #484848;
  --pfz-border-secondary-dropshadow: #c0c0cc;
  --pfz-border-warning-secondary: #ffe3a5;
  --pfz-border-default-secondary: #d4d4d8;
  --pfz-border-default-disabled: var(--pfz-color-gray-10);
  --pfz-border-container-dropshadow: var(--pfz-color-black);
  --pfz-border-secondary: #aaaaaa;
  --pfz-border-success: #008253;
  --pfz-border-success-tertiary: #a4f6ca;
  --pfz-border-error: #880112;
  --pfz-border-tag-error: #ffccce;
  --pfz-border-secondary-error: #c60e2d;
  --pfz-border-informational: #0000c9;
  --pfz-border-BERT-excellent: var(--pfz-background-BERT-excellent);
  --pfz-border-BERT-good: #fff066;
  --pfz-border-BERT-notgreat: var(--pfz-background-BERT-notgreat);

  /* Base font size */
  --pfz-base-font-size: 16px;

  /* Master font size variables based on size */
  --pfz-font-size-1: 0.75rem; /* 12px */
  --pfz-font-size-2: 0.875rem; /* 14px */
  --pfz-font-size-3: 1rem; /* 16px */
  --pfz-font-size-4: 1.25rem; /* 20px */
  --pfz-font-size-5: 1.5rem; /* 24px */
  --pfz-font-size-6: 1.75rem; /* 28px */
  --pfz-font-size-7: 1.875rem; /* 30px */
  --pfz-font-size-8: 2.25rem; /* 36px */
  --pfz-font-size-9: 3rem; /* 48px */
  --pfz-font-size-10: 4rem; /* 64px */

  /* Semantic font size variables mapped to UI elements */
  --pfz-font-size-heading-primary: var(--pfz-font-size-10);
  --pfz-font-size-heading-secondary: var(--pfz-font-size-9);
  --pfz-font-size-heading-tertiary: var(--pfz-font-size-8);
  --pfz-font-size-heading-quaternary: var(--pfz-font-size-7);
  --pfz-font-size-heading-small: var(--pfz-font-size-6);
  --pfz-font-size-heading-minor: var(--pfz-font-size-5);
  --pfz-font-size-text-body: var(--pfz-font-size-4);
  --pfz-font-size-text-small: var(--pfz-font-size-3);
  --pfz-font-size-text-tiny: var(--pfz-font-size-2);
  --pfz-font-size-text-minuscule: var(--pfz-font-size-1);

  /* font weights*/

  --pfz-font-weight-400: 400;
  --pfz-font-weight-500: 500;
  --pfz-font-weight-700: 700;
  --pfz-font-weight-800: 800;

  /*line heights*/
  --pfz-line-height-12: 0.75rem; /* 12px */
  --pfz-line-height-18: 1.125rem; /* 18px */
  --pfz-line-height-20: 1.25rem; /* 20px */
  --pfz-line-height-24: 1.5rem; /* 24px */
  --pfz-line-height-30: 1.875rem; /*30px*/
  --pfz-line-height-36: 2.25rem; /* 36px */
  --pfz-line-height-54: 3.375rem; /* 54px */
  --pfz-line-height-60: 3.75rem; /* 60px */
  --pfz-text-required: 1.125rem; /* 18px */
  --pfz-text-instruction-line-heigh: 1.1375rem; /* 18.2px */
  --pfz-icon-text-line-heigh: 1.3125rem; /* 21px */
  --pfz-label-line-height: 1.5rem; /* 24px */
  --line-height-9: 2.25rem; /* 36px */
  --pfz-tab-line-height: 1.3rem; /* 20.8px */

  /* heights */
  --h-6: 1.5rem; /* 24px */

  /*paddings*/
  --pfz-marketing-form-group-margin: 24px;
  --pfz-marketing-form-label-margin: 24px; /* 10px */
  --pfz-feedback-padding-28: 28px;
  --pfz-feedback-padding-54: 54px;

  /* font-families */
  --font-family-primary: PfizerDiatypeRegular;
  --font-family-secondary: PfizerTomorrowRegular;

  /* z-index*/
  --pfz-tooltip-zindex: 1100;

  /*box-shadow*/
  --pfz-tooltip-box-shadow-color: #0000001a;

  /* dropdown select gap */
  --pfz-dropdown-gap: 8px;

  /* heights */
  --pfz-height-header-nav: 65px;

  /* Fixed Dimensions */
  --pfz-modal-width: 350px;
  --pfz-footer-height: 88px;
  --pfz-email-modules-tile-width: 204px;
  --pfz-email-modules-tile-height: 212px;
  --pfz-content-header-tooltip-width: 280px;

  /* nlp score themes */
  --pfz-nlp-score-one-bg: #c60e2d40;
  --pfz-nlp-score-two-bg: #c60e2dbf;
  --pfz-nlp-score-three-bg: #c60e2d;
  --pfz-nlp-score-four-bg: #ffe60040;
  --pfz-nlp-score-five-bg: #ffe60080;
  --pfz-nlp-score-six-bg: #ffe600;
  --pfz-nlp-score-seven-bg: #00825240;
  --pfz-nlp-score-eight-bg: #008252bf;
  --pfz-nlp-score-nine-bg: #008252;
  --pfz-nlp-score-ten-bg: #015437;

  /* Toaster */
  /* NEUTRAL */
  --pfz-toast-neutral-border-color: var(--pfz-border-default-secondary);
  --pfz-toast-neutral-background-color: var(--pfz-color-white);
  --pfz-toast-neutral-color: var(--pfz-color-primary-800);

  /* INFO */
  --pfz-toast-info-border-color: var(--pfz-color-primary-800);
  --pfz-toast-info-background-color: var(--pfz-color-white);
  --pfz-toast-info-color: var(--pfz-color-primary-800);

  /* WARNING */
  --pfz-toast-warning-border-color: var(--pfz-color-orange-500);
  --pfz-toast-warning-background-color: var(--pfz-color-orange-200);
  --pfz-toast-warning-color: var(--pfz-color-orange-500);

  /* ERROR */
  --pfz-toast-error-border-color: var(--pfz-color-red-300);
  --pfz-toast-error-background-color: var(--pfz-color-red-100);
  --pfz-toast-error-color: var(--pfz-color-red-300);

  /* SUCCESS */
  --pfz-toast-success-border-color: var(--pfz-color-green-200);
  --pfz-toast-success-background-color: var(--pfz-color-green-100);
  --pfz-toast-success-color: var(--pfz-color-green-200);

  /* GRIDS */
  --pfz-grid-track-size-64: 64px;
  --pfz-grid-track-size-68: 68px;
}
