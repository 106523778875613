:root {
  /* Colors */
  --color-bg-light: #ffffff;
  --color-neutral-1: #9e9e9e;
  --color-neutral-2: #dadada;
  --color-neutral-3: #505050;
  --color-neutral-4: #757575;
  --color-neutral-5: #d9d9d9;
  --color-neutral-6: #98a2b3;
  --color-neutral-7: #6f7171;
  --color-neutral-8: #e4e4e7;
  --color-transparent: transparent;
  --color-text-footer: #6e7781;
  --color-interactive-disabled: #f0efee;
  --text-primary-onlight: #0a1414;
  --background-success: #ccf7e8;
  --background-informational: #f8fcff;

  /* Spacing */
  --spacing-01: 0.25rem; /* 4px */
  --spacing-02: 0.5rem; /* 8px */
  --spacing-03: 0.75rem; /* 12px */
  --spacing-04: 1rem; /* 16px */
  --spacing-05: 1.25rem; /* 20px */
  --spacing-06: 1.5rem; /* 24px */
  --spacing-07: 1.75rem; /* 28px */
  --spacing-08: 2rem; /* 32px */
  --spacing-09: 2.25rem; /* 36px */
  --spacing-10: 2.5rem; /* 40px */
  --spacing-11: 2.75rem; /* 44px */
  --spacing-12: 3rem; /* 48px */
  --spacing-13: 3.25rem; /* 52px */
  --spacing-14: 3.5rem; /* 56px */
  --spacing-15: 3.75rem; /* 60px */
  --spacing-16: 4rem; /* 64px */
  --spacing-17: 4.25rem; /* 68px */
  --spacing-18: 4.5rem; /* 72px */
  --spacing-19: 4.75rem; /* 76px */
  --spacing-20: 5rem; /* 80px */

  /* Font Sizes */
  --font-size-xs: 0.75rem;
  /* equivalent to 12px */
  --font-size-s: 0.875rem;
  /* equivalent to 14px */
  --font-size-md: 1rem;
  /* equivalent to 16px */
  --font-size-lg: 1.125rem;
  /* equivalent to 18px */
  --font-size-l: 1.25rem;
  /* equivalent to 20 px */
  --font-size-xl: 1.5rem;
  /* equivalent to 24px */
  --font-size-xxl: 2rem;
  /* equivalent to 32px */
  --font-size-3xl: 2.125rem;
  /* equivalent to 36px */

  /* Line Heights */

  /* Font Weights */
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  /* Border */

  --border-0: 0px;
  --border-2: 2px;
  --border-4: 4px;
  --border-8: 8px;
  --border-1: 1px;

  /* Border Radius */

  --border-radius-sm: 0.125rem; /* 2px */
  --border-radius-rounded: 0.25rem; /* 4px */
  --border-radius-rounded-md: 0.375rem; /* 6px */
  --border-radius-lg: 0.5rem; /* 8px */
  --border-radius-xl: 0.75rem; /* 12px */
  --border-radius-2xl: 1rem; /* 16px */
  --border-radius-3xl: 1.25rem; /* 20px */
  --border-radius-4xl: 1.5rem; /* 24px */
  --border-radius-full: 624.9375rem; /* 9999px */

  /* Shadows */
  --shadow-shallow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1) /* Zindexes */;

  /* Padding Sizes */
  --padding-size-xxxs: 0.5rem;
  /* equivalent to 8px */
  --padding-size-xxs: 0.625rem;
  /* equivalent to 10px */
  --padding-size-xs: 0.75rem;
  /* equivalent to 12px */
  --padding-size-s: 0.875rem;
  /* equivalent to 14px */
  --padding-size-md: 1rem;
  /* equivalent to 16px */
  --padding-size-lg: 1.125rem;
  /* equivalent to 18px */
  --padding-size-l: 1.25rem;
  /* equivalent to 20px */
  --padding-size-xl: 1.5rem;
  /* equivalent to 24px */
  --padding-size-xxl: 2rem;
  /* equivalent to 32px */

  /* margin Sizes */
  --margin-size-xxs: 0.625rem;
  /* equivalent to 10px */
  --margin-size-xs: 0.75rem;
  /* equivalent to 12px */
  --margin-size-s: 0.875rem;
  /* equivalent to 14px */
  --margin-size-md: 1rem;
  /* equivalent to 16px */
  --margin-size-lg: 1.125rem;
  /* equivalent to 18px */
  --margin-size-l: 1.25rem;
  /* equivalent to 20px */
  --margin-size-xl: 1.5rem;
  /* equivalent to 24px */
  --margin-size-xxl: 2rem;
  /* equivalent to 32px */

  /* Markdown style units*/
  --markdown-font-weight-heading: 700;
  --markdown-font-weight-paragraph: 400;
  --markdown-font-weight-sup: 400;
  --markdown-font-weight-strong: 700;
  --markdown-line-height: 1.244rem;
  --markdown-padding-bottom: 1.75rem;
  /* card Loading indicator animation */
  --card-glow-animation-primary-color: #fff;
  --card-glow-animation-secondary-color: #d9d9d9;

  /* width of the control panel*/
  --pfz-width-control-panel: 360px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* For Chrome & Safari */
/* .pfz-cggenai-scrollable::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.pfz-cggenai-scrollable::-webkit-scrollbar-thumb {
  background: blue;
  border-radius: 50px;
}
.pfz-cggenai-scrollable::-webkit-scrollbar-track {
  background: red;
  border-radius: 50px;
} */

/* Standard version (Firefox only for now) */
/* .pfz-cggenai-scrollable {
  scrollbar-color: #999 #333;
  scrollbar-width: thin;
} */
