.access-forbidden__page-wrapper {
  display: grid;
  width: 100%;
  height: 100vh;
  padding-left: 210px;
  justify-items: center;
  align-items: center;
}

.access-forbidden__page-content {
  padding: var(--padding-size-xxl);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-l);
  font-weight: var(--pfz-font-weight-600);
  text-align: center;
}
