.tooltip {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.tooltip__text--hidden {
  display: none;
}

.tooltip__text {
  z-index: 10000 !important;
  position: absolute;
  padding: 12px 18px;
  font-size: var(--pfz-font-size-2);
  font-weight: var(--pfz-font-weight-400);
  line-height: var(--pfz-icon-text-line-heigh);
  word-wrap: break-word;
  z-index: var(--pfz-tooltip-zindex);
  border: 1px solid var();
  border-radius: var(--border-radius-lg);
  background: var(--pfz-background-primary);
  box-shadow: 0px 4px 24px 0px var(--pfz-tooltip-box-shadow-color);
  text-align: left;
}

.tooltip__text-regular {
  width: 179px;
}

.tooltip__text-large {
  width: 350px;
}

.tooltip-theme--default {
  color: var(--pfz-color-gray-400);
  background: var(--pfz-background-primary);
}

.tooltip-theme--contrast {
  border: 1px solid var(--pfz-border-informational);
  background: var(--pfz-background-informational);
}

.tooltip__text--top-right {
  bottom: 25px;
  left: 0px;
}

.tooltip__text--top-left {
  bottom: 25px;
  right: 0px;
}

.tooltip__text--top-center {
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
}

.tooltip__text--bottom-left {
  right: 0;
  top: 25px;
}

.tooltip__text--bottom-right {
  top: 25px;
}

.tooltip__text--bottom-center {
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
}
