@font-face {
  font-family: 'PfizerDiatypeBlack';
  src:
    url('../fonts/PfizerDiatype-Black.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-Black.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-Black.woff') format('woff'),
    url('../fonts/PfizerDiatype-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeBlackItalic';
  src:
    url('../fonts/PfizerDiatype-BlackItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-BlackItalic.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-BlackItalic.woff') format('woff'),
    url('../fonts/PfizerDiatype-BlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeBold';
  src:
    url('../fonts/PfizerDiatype-Bold.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-Bold.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-Bold.woff') format('woff'),
    url('../fonts/PfizerDiatype-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeBoldItalic';
  src:
    url('../fonts/PfizerDiatype-BoldItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-BoldItalic.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-BoldItalic.woff') format('woff'),
    url('../fonts/PfizerDiatype-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeHeavy';
  src:
    url('../fonts/PfizerDiatype-Heavy.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-Heavy.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-Heavy.woff') format('woff'),
    url('../fonts/PfizerDiatype-Heavy.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeHeavyItalic';
  src:
    url('../fonts/PfizerDiatype-HeavyItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-HeavyItalic.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-HeavyItalic.woff') format('woff'),
    url('../fonts/PfizerDiatype-HeavyItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeLightItalic';
  src:
    url('../fonts/PfizerDiatype-LightItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-LightItalic.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-LightItalic.woff') format('woff'),
    url('../fonts/PfizerDiatype-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeMedium';
  src:
    url('../fonts/PfizerDiatype-Medium.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-Medium.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-Medium.woff') format('woff'),
    url('../fonts/PfizerDiatype-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeMediumItalic';
  src:
    url('../fonts/PfizerDiatype-MediumItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-MediumItalic.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-MediumItalic.woff') format('woff'),
    url('../fonts/PfizerDiatype-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeRegular';
  src:
    url('../fonts/PfizerDiatype-Regular.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-Regular.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-Regular.woff') format('woff'),
    url('../fonts/PfizerDiatype-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerDiatypeRegularItalic';
  src:
    url('../fonts/PfizerDiatype-RegularItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerDiatype-RegularItalic.ttf') format('truetype'),
    url('../fonts/PfizerDiatype-RegularItalic.woff') format('woff'),
    url('../fonts/PfizerDiatype-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerTomorrowBlack';
  src:
    url('../fonts/PfizerTomorrow-Black.eot') format('embedded-opentype'),
    url('../fonts/PfizerTomorrow-Black.ttf') format('truetype'),
    url('../fonts/PfizerTomorrow-Black.woff') format('woff'),
    url('../fonts/PfizerTomorrow-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerTomorrowBlackItalic';
  src:
    url('../fonts/PfizerTomorrow-BlackItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerTomorrow-BlackItalic.ttf') format('truetype'),
    url('../fonts/PfizerTomorrow-BlackItalic.woff') format('woff'),
    url('../fonts/PfizerTomorrow-BlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerTomorrowBold';
  src:
    url('../fonts/PfizerTomorrow-Bold.eot') format('embedded-opentype'),
    url('../fonts/PfizerTomorrow-Bold.ttf') format('truetype'),
    url('../fonts/PfizerTomorrow-Bold.woff') format('woff'),
    url('../fonts/PfizerTomorrow-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerTomorrowBoldItalic';
  src:
    url('../fonts/PfizerTomorrow-BoldItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerTomorrow-BoldItalic.ttf') format('truetype'),
    url('../fonts/PfizerTomorrow-BoldItalic.woff') format('woff'),
    url('../fonts/PfizerTomorrow-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerTomorrowRegular';
  src:
    url('../fonts/PfizerTomorrow-Regular.eot') format('embedded-opentype'),
    url('../fonts/PfizerTomorrow-Regular.ttf') format('truetype'),
    url('../fonts/PfizerTomorrow-Regular.woff') format('woff'),
    url('../fonts/PfizerTomorrow-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'PfizerTomorrowRegularItalic';
  src:
    url('../fonts/PfizerTomorrow-RegularItalic.eot') format('embedded-opentype'),
    url('../fonts/PfizerTomorrow-RegularItalic.ttf') format('truetype'),
    url('../fonts/PfizerTomorrow-RegularItalic.woff') format('woff'),
    url('../fonts/PfizerTomorrow-RegularItalic.woff2') format('woff2');
}
