.snackbar__container {
  position: fixed;
  top: 0;
  width: 500px;
  padding: var(--padding-size-md);
  border-radius: var(--border-radius-lg);
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  animation: slide-from-left 0.3s ease forwards;
  opacity: 0;
  z-index: 9999999;
  gap: 16px;
  box-shadow:
    0px 4px 8px -2px rgba(2, 0, 94, 0.1),
    0px 2px 4px -2px rgba(2, 0, 94, 0.06);
}

/* Container Styles */
.snackbar__container-neutral {
  border-color: var(--pfz-toast-neutral-border-color);
  background-color: var(--pfz-toast-neutral-background-color);
}

.snackbar__container-info {
  border-color: var(--pfz-toast-info-border-color);
  background-color: var(--pfz-toast-info-background-color);
}

.snackbar__container-success {
  border-color: var(--pfz-toast-success-border-color);
  background-color: var(--pfz-toast-success-background-color);
}

.snackbar__container-error {
  border-color: var(--pfz-toast-error-border-color);
  background-color: var(--pfz-toast-error-background-color);
}

.snackbar__container-warning {
  border-color: var(--pfz-toast-warning-border-color);
  background-color: var(--pfz-toast-warning-background-color);
}

/* Notification text styles */
.snackbar__container__msgwrapper {
  flex: 1;
}

.snackbar__notification-titled {
  align-self: stretch;
  gap: var(--padding-size-lg);
  font-size: var(--pfz-font-size-2);
}

.snackbar__container__notification__text {
  font-size: var(--pfz-font-size-2);
  color: var(--pfz-text-default-color);
  font-weight: var(--pfz-font-weight-400);
  line-height: var(--pfz-line-height-20);
}

.snackbar__container__notification__title {
  word-spacing: normal;
  font-size: var(--pfz-font-size-3);
  color: var(--pfz-text-default-color);
  font-weight: var(--pfz-font-weight-700);
  line-height: var(--pfz-line-height-24);
}

/* Position styles */
.snackbar__container--top-center {
  left: 50%;
  transform: translatex(-50%);
}

.snackbar__container--top-right {
  right: 41px;
}

.snackbar__container--bottom-center {
  top: unset;
  left: 52.5%;
  transform: translatex(-50%);
  animation: slide-from-bottom 0.3s ease forwards !important;
}

@keyframes slide-from-left {
  100% {
    top: 1rem;
    opacity: 1;
  }
}

@keyframes slide-from-bottom {
  100% {
    bottom: 84px;
    opacity: 1;
  }
}

/* CTA styles */
.snackbar--cta {
  height: 32px;
  width: 80px;
  align-self: unset;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  line-height: var(--pfz-line-height-24);
  border-width: 2px;
  border-style: solid;
  background-color: transparent;
  padding: var(--spacing-01);
}

.snackbar--cta:hover {
  background-color: transparent !important;
}

.snackbar__container-neutral .snackbar--cta {
  border-color: var(--pfz-toast-neutral-border-color) !important;
  color: var(--pfz-toast-neutral-color) !important;
}

.snackbar__container-info .snackbar--cta {
  border-color: var(--pfz-toast-info-border-color) !important;
  color: var(--pfz-toast-info-color) !important;
}

.snackbar__container-success .snackbar--cta {
  border-color: var(--pfz-toast-success-border-color) !important;
  color: var(--pfz-toast-success-color) !important;
}

.snackbar__container-error .snackbar--cta {
  border-color: var(--pfz-toast-error-border-color) !important;
  color: var(--pfz-toast-error-color) !important;
}

.snackbar__container-warning .snackbar--cta {
  border-color: var(--pfz-toast-warning-border-color) !important;
  color: var(--pfz-toast-warning-color) !important;
}

/* icon styles */
.snackbar__container-info .snackbar--icon {
  color: var(--pfz-toast-info-color);
}

.snackbar__container-success .snackbar--icon {
  color: var(--pfz-toast-success-color);
}

.snackbar__container-error .snackbar--icon {
  color: var(--pfz-toast-error-color);
}

.snackbar__container-warning .snackbar--icon {
  color: var(--pfz-toast-warning-color);
}
